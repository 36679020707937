import React from "react";
import { graphql } from "gatsby";
import {
  Container,
  Layout,
  ListingItem,
  PostsGrid,
  PageNavigation,
} from "../components/page";
import { SEO } from "../components/metadata/index";

import { SiteData } from "../hooks/site-data";


/* eslint-disable react/prop-types */
const Paginated = ({ data, pageContext }) => {
  const siteData = SiteData();
  const siteTitle = siteData.site.siteMetadata.title;
  const posts = data.allMdx.edges;
  const { currentPage, numPages, postType } = pageContext;

  return (
    <Layout title={siteTitle}>
      <SEO title={`/${postType}`} />
      <Container>
        <PostsGrid>
          {posts.map(({ node }) => {
            return (
              <ListingItem key={node.slug} node={node} />
            );
          })}
        </PostsGrid>
        <PageNavigation
          currentPage={currentPage}
          numPages={numPages}
          postType={postType} />

      </Container>
    </Layout>
  )
}

export default Paginated;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $slug_regex: String!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {slug: {regex: $slug_regex}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        ...ListingItemBasicData
      }
      totalCount
    }
  }
`
